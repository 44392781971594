import React, { PureComponent } from "react";
import { MdClose } from "react-icons/md";

import { authorizedFetch } from "../../utils/authorizedFetch";
import { ServerErrorMessages } from "../../types/serverErrorMessages";
import config from "../../config";

import "./MatterToggler.css";

export const actions = {
    NONE: "none",
    PRODUCE: "PRODUCE",
    RELOAD: "RELOAD",
    RECYCLE: "RECYCLE"
};

export class MatterToggler extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.matter.id,
            action: props.matter.action || actions.NONE,
            enabled: true
        };

        this.changeAction = this.changeAction.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.matter.action !== prevProps.matter.action) {
            this.setState({
                action: this.props.matter.action
            });
        }
    }

    render() {
        return (
            <td className="matter-toggler__wrapper">
                <div
                    title={MatterToggler.getActionHint(
                        this.props.matter.name,
                        this.state.action
                    )}
                    className={`matter-toggler ${this.getClassName(
                        this.state.action
                    )} ${!this.state.enabled ? "cursor-wait" : ""}`}
                    onClick={this.changeAction}
                >
                    <div className="matter">{this.props.matter.shortName}</div>
                    {this.renderDeleteButton()}
                </div>
            </td>
        );
    }

    renderDeleteButton() {
        if (this.state.action === actions.NONE) {
            return null;
        }

        return (
            <MdClose
                className="delete"
                aria-hidden="true"
                onClick={this.deleteAction}
            />
        );
    }

    getClassName = action => {
        switch (action || actions.NONE) {
            case actions.NONE:
                return "none";
            case actions.PRODUCE:
                return "download";
            case actions.RELOAD:
                return "reload";
            case actions.RECYCLE:
                return "unload";
            default:
                // should never happen
                console.log("Unexpected action: " + action);
                return "";
        }
    };

    getNextAction = action => {
        switch (action || actions.NONE) {
            case actions.NONE:
                return actions.PRODUCE;
            case actions.PRODUCE:
                return actions.RELOAD;
            case actions.RELOAD:
                return actions.RECYCLE;
            case actions.RECYCLE:
                return actions.NONE;
            default:
                // should never happen
                console.log("Unexpected action: " + action);
                return null;
        }
    };

    changeAction() {
        if (!this.state.enabled) {
            return;
        }

        const that = this;
        const nextAction = this.getNextAction(this.state.action);

        this.setState({ enabled: false }, () => {
            switch (nextAction) {
                case actions.PRODUCE: {
                    authorizedFetch(
                        `${config.protocolAndHost}/api/data/pointmatters`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                matterId: this.props.matter.matterId,
                                pointId: this.props.matter.pointId,
                                action: actions.PRODUCE
                            })
                        }
                    )
                        .then(response => {
                            return Promise.all([
                                response.status,
                                response.json()
                            ]).then(([status, body]) => ({ body, status }));
                        })
                        .then(result => {
                            if (result.status === 201) {
                                that.setState({
                                    id: result.body.id,
                                    action: actions.PRODUCE
                                });
                            } else if (that.props.onError) {
                                that.props.onError(
                                    ServerErrorMessages[result.status] ||
                                        "Не удалось сохранить изменение"
                                );
                            }
                        })
                        .catch(() => {
                            if (that.props.onError) {
                                that.props.onError(
                                    "Не удалось сохранить изменение"
                                );
                            }
                        })
                        .finally(() => that.setState({ enabled: true }));

                    break;
                }

                case actions.RELOAD:
                case actions.RECYCLE: {
                    authorizedFetch(
                        `${config.protocolAndHost}/api/data/pointmatters/${this.state.id}`,
                        {
                            method: "PATCH",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                action: nextAction
                            })
                        }
                    )
                        .then(response => {
                            if (response.status === 200) {
                                that.setState({
                                    action: nextAction
                                });
                            } else if (that.props.onError) {
                                that.props.onError(
                                    ServerErrorMessages[response.status] ||
                                        "Не удалось сохранить изменение"
                                );
                            }
                        })
                        .catch(() => {
                            if (that.props.onError) {
                                that.props.onError(
                                    "Не удалось сохранить изменение"
                                );
                            }
                        })
                        .finally(() => that.setState({ enabled: true }));
                    break;
                }

                case actions.NONE: {
                    authorizedFetch(
                        `${config.protocolAndHost}/api/data/pointmatters/${this.state.id}`,
                        {
                            method: "DELETE"
                        }
                    )
                        .then(response => {
                            if (
                                response.status === 204 ||
                                response.status === 404
                            ) {
                                that.setState({
                                    action: nextAction
                                });
                            } else if (that.props.onError) {
                                that.props.onError(
                                    ServerErrorMessages[response.status] ||
                                        "Не удалось удалить"
                                );
                            }
                        })
                        .catch(() => {
                            if (that.props.onError) {
                                that.props.onError("Не удалось удалить");
                            }
                        })
                        .finally(() => that.setState({ enabled: true }));
                    break;
                }

                default: {
                    console.log("Invalid next action: " + nextAction);
                }
            }
        });
    }

    deleteAction(event) {
        if (!this.state.enabled) {
            return;
        }

        event.stopPropagation();
        const that = this;

        this.setState({ enabled: false }, () => {
            authorizedFetch(
                `${config.protocolAndHost}/api/data/pointmatters/${this.state.id}`,
                {
                    method: "DELETE"
                }
            )
                .then(response => {
                    if (response.status === 204 || response.status === 404) {
                        that.setState({
                            action: actions.NONE
                        });
                    } else if (that.props.onError) {
                        that.props.onError(
                            ServerErrorMessages[response.status] ||
                                "Не удалось удалить"
                        );
                    }
                })
                .catch(() => {
                    if (that.props.onError) {
                        that.props.onError("Не удалось удалить");
                    }
                })
                .finally(() => that.setState({ enabled: true }));
        });
    }

    static getActionHint(matterName, action) {
        switch (action || actions.NONE) {
            case actions.NONE:
                return "";
            case actions.PRODUCE:
                return "Сбор: " + matterName;
            case actions.RELOAD:
                return "Перегрузка: " + matterName;
            case actions.RECYCLE:
                return "Переработка: " + matterName;
            default:
                // should never happen
                console.log("Unexpected action: " + action);
                return "";
        }
    }
}
