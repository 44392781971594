import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
    Button,
    FormControl,
    OverlayTrigger,
    Tab,
    Tabs,
    Tooltip
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";

import { CarriersLogisticsView } from "../components/DispatcherComponents/CarriersLogisticsView";
import { PointsLogisticsView } from "../components/DispatcherComponents/PointsLogisticsView";
import { CarriersView } from "../components/DispatcherComponents/CarriersView";
import { PointsView } from "../components/DispatcherComponents/PointsView";
import { ChecksView } from "../components/DispatcherComponents/ChecksView";
import { NewEventModal } from "../components/DispatcherComponents/NewEventModal";
import { AlertDanger } from "../components/alerts/AlertDanger";

import { changeWarning } from "../store/warning/actions";

import { selectedEventKey } from "../utils/getSelectedCarriersLocalStorageKey";
import { getData } from "../utils/getData";
import config from "../config";

import "./Dispatcher.css";

const viewTypes = {
    POINTS_LOGISTICS: "points_logistics",
    CARRIERS_LOGISTICS: "carriers_logistics",
    POINTS: "points",
    CARRIERS: "carriers",
    CHECKS: "checks"
};

const selectedViewKey = "dispatcher_selectedView";

class DispatcherPresenter extends PureComponent {
    static defaultState = {
        error: null,
        warning: null,
        carriers: [],
        events: [],
        matters: [],
        basePoints: [],
        points: [],
        pointsMap: {},
        pointsInfo: {},
        carriersMap: {},
        showNewCarrierModal: false
    };

    constructor(props) {
        super(props);

        const state = Dispatcher.defaultState;

        state.selectedView =
            localStorage.getItem(selectedViewKey) ||
            viewTypes.CARRIERS_LOGISTICS;

        const selectedEvent = localStorage.getItem(selectedEventKey);

        if (selectedEvent) {
            state.selectedEvent = Number(selectedEvent);
        }

        this.state = state;

        this.onError = this.onError.bind(this);
        this.updateCarriers = this.updateCarriers.bind(this);
        this.updatePoints = this.updatePoints.bind(this);
        this.updateBasePoints = this.updateBasePoints.bind(this);
        this.changeView = this.changeView.bind(this);
        this.changeSelectedEvent = this.changeSelectedEvent.bind(this);
        this.showNewEventModal = this.showNewEventModal.bind(this);
        this.hideNewEventModal = this.hideNewEventModal.bind(this);
        this.addNewEvent = this.addNewEvent.bind(this);
    }

    componentDidMount() {
        this.updateEvents();
        this.updateBasePoints();
        this.updateMatters();

        if (this.state.selectedEvent) {
            this.updateCarriers();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedEvent !== this.state.selectedEvent) {
            localStorage.setItem(selectedEventKey, this.state.selectedEvent);

            this.updateMatters();
            this.updateCarriers();
            this.updatePoints();
        }
    }

    render() {
        if (this.state.error) {
            return (
                <div className="main-content">
                    <AlertDanger text={this.state.error} />
                </div>
            );
        }

        return [
            <div className="main-content" key="body">
                {this.renderMenu()}
                {this.renderContent()}
            </div>
        ];
    }

    renderMenu() {
        return (
            <div className="menu">
                <Tabs
                    name="options"
                    activeKey={this.state.selectedView}
                    onSelect={this.changeView}
                >
                    <Tab
                        eventKey={viewTypes.CARRIERS_LOGISTICS}
                        title="Логистика по перевозчикам"
                    />
                    <Tab
                        eventKey={viewTypes.POINTS_LOGISTICS}
                        title="Логистика по точкам"
                    />
                    <Tab eventKey={viewTypes.CARRIERS} title={`Перевозчики`} />
                    <Tab eventKey={viewTypes.POINTS} title={`Точки`} />
                    <Tab eventKey={viewTypes.CHECKS} title={`Предупреждения`} />
                </Tabs>
                <div className="event">
                    <FormControl
                        as="select"
                        value={this.state.selectedEvent}
                        onChange={this.changeSelectedEvent}
                        className="event-dropdown w-auto"
                    >
                        {this.state.events.map(event => (
                            <option key={event.id} value={event.id}>
                                {event.date}
                            </option>
                        ))}
                    </FormControl>
                    <OverlayTrigger
                        placement="auto"
                        overlay={<Tooltip>Создать новую акцию</Tooltip>}
                        trigger="hover"
                    >
                        <Button
                            className="event-button border-0 shadow-none"
                            onClick={this.showNewEventModal}
                        >
                            <FaPlus />
                        </Button>
                    </OverlayTrigger>
                </div>
                <NewEventModal
                    key="newEvent"
                    events={this.state.events}
                    show={this.state.showNewEventModal}
                    onHide={this.hideNewEventModal}
                    onSave={this.addNewEvent}
                />
            </div>
        );
    }

    renderContent() {
        switch (this.state.selectedView) {
            case viewTypes.CHECKS:
                return (
                    <ChecksView
                        selectedEvent={this.state.selectedEvent}
                        onError={this.props.changeWarning}
                    />
                );
            case viewTypes.CARRIERS:
                return (
                    <CarriersView
                        selectedEvent={this.state.selectedEvent}
                        events={this.state.events}
                        onCarriersChange={this.updateCarriers}
                        onError={this.props.changeWarning}
                    />
                );
            case viewTypes.POINTS:
                return (
                    <PointsView
                        points={this.state.points}
                        basePoints={this.state.basePoints}
                        pointsMap={this.state.pointsMap}
                        selectedEvent={this.state.selectedEvent}
                        onPointsChange={this.updatePoints}
                        onBasePointsChange={this.updateBasePoints}
                        onError={this.props.changeWarning}
                        matters={this.state.allMatters}
                        pointMattersMap={this.state.pointMattersMap}
                    />
                );
            case viewTypes.POINTS_LOGISTICS:
                return (
                    <PointsLogisticsView
                        matters={this.state.eventMatters}
                        points={this.state.points}
                        pointsMap={this.state.pointsMap}
                        carriersMap={this.state.carriersMap}
                        selectedEvent={this.state.selectedEvent}
                        carriers={this.state.carriers}
                        onError={this.props.changeWarning}
                    />
                );
            case viewTypes.CARRIERS_LOGISTICS:
            default:
                return (
                    <CarriersLogisticsView
                        matters={this.state.eventMatters}
                        points={this.state.points}
                        pointsInfo={this.state.pointsInfo}
                        carriers={this.state.carriers}
                        selectedEvent={this.state.selectedEvent}
                        onCarriersChange={this.updateCarriers}
                        onError={this.props.changeWarning}
                    />
                );
        }
    }

    updateMatters() {
        getData({
            url: `${config.protocolAndHost}/api/data/matters?eventId=${this.state.selectedEvent}&action=PRODUCE`
        })
            .then(result => {
                this.setState({
                    eventMatters: result
                });
            })
            .catch(this.onError);

        getData({
            url: `${config.protocolAndHost}/api/data/matters`
        })
            .then(result => {
                this.setState({
                    allMatters: result
                });
            })
            .catch(this.onError);
    }

    updateEvents() {
        getData({
            url: `${config.protocolAndHost}/api/data/events`
        })
            .then(events => {
                const event = this.state.selectedEvent;
                const selectedEvent = Dispatcher.isValidEvent(events, event)
                    ? event
                    : Dispatcher.getFirstEventId(events);

                this.setState({
                    events: events,
                    selectedEvent
                });
            })
            .catch(this.onError);
    }

    updatePoints() {
        if (!this.state.basePointsMap) {
            return;
        }

        getData({
            url: `${config.protocolAndHost}/api/data/points?eventId=${this.state.selectedEvent}`
        })
            .then(result => {
                const points = result
                    .map(item => ({
                        id: item.id,
                        name: this.state.basePointsMap[item.basePointId].name
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name, "en"));

                const pointsMap = points.reduce((acc, item) => {
                    acc[item.id] = item.name;
                    return acc;
                }, {});

                const pointsInfo = result.reduce((acc, item) => {
                    acc[item.id] = {
                        name: this.state.basePointsMap[item.basePointId].name,
                        address: this.state.basePointsMap[item.basePointId]
                            .address,
                        basePointId: item.basePointId
                    };
                    return acc;
                }, {});

                this.setState({
                    points,
                    pointsMap,
                    pointsInfo
                });
            })
            .catch(this.onError);
    }

    updateBasePoints() {
        getData({
            url: `${config.protocolAndHost}/api/data/basepoints`
        }).then(result => {
            const basePointsMap = result.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});

            this.setState(
                {
                    basePoints: result,
                    basePointsMap
                },
                () => {
                    this.updatePoints();
                }
            );
        });
    }

    updateCarriers() {
        getData({
            url: `${config.protocolAndHost}/api/view/carriers/names?eventId=${this.state.selectedEvent}`
        })
            .then(result => {
                const carriersMap = result.reduce((acc, item) => {
                    acc[item.id] = item.name;
                    return acc;
                }, {});

                this.setState({
                    carriers: result,
                    carriersMap
                });
            })
            .catch(this.onError);
    }

    showNewEventModal() {
        this.setState({ showNewEventModal: true });
    }

    hideNewEventModal() {
        this.setState({ showNewEventModal: false });
    }

    addNewEvent(event) {
        this.setState(state => ({
            events: [event, ...state.events],
            selectedEvent: event.id
        }));
    }

    changeView(viewId) {
        localStorage.setItem(selectedViewKey, viewId);

        this.setState({ selectedView: viewId });
    }

    changeSelectedEvent(event) {
        const eventId = Number(event.target.value);

        this.setState({
            selectedEvent: eventId
        });
    }

    onError(error) {
        this.setState({ error: error.message });
    }

    static isValidEvent(events, selectedEvent) {
        return Boolean(events.find(event => event.id === selectedEvent));
    }

    static getFirstEventId(events) {
        return events && events[0] && events[0].id;
    }
}

const mapDispatchToProps = dispatch => ({
    changeWarning: warning => dispatch(changeWarning(warning))
});

export const Dispatcher = connect(
    null,
    mapDispatchToProps
)(DispatcherPresenter);
